import CalculatedDriver from "../CalculatedDriver";
import { RevenueTypes } from "../../../components/constants/finance";
import { DriverCategories, SpecialChar, UnitTypes } from "../CalculatedDriver/constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import { WorkingCapitalTypes } from "../WorkingCapital/constants";
import { PERMISSIONS } from "../../Permissions/Permissions";
import { DialogTypes } from "../constants";

class Revenue extends FinanceGeneric {
  RevenueName = "";
  RevenueType = RevenueTypes.RevenueOnly;
  ID_ParentRevenue = null;
  IsCreated = false;

  constructor(db_record = null) {
    super(db_record);
    this.db_record = db_record;
    this.clean();
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.RevenueName = this.db_record.RevenueName;
      this.RevenueType = this.db_record.RevenueType;
      this.ID_ParentRevenue = this.db_record.ID_ParentRevenue;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = {
    Total: {
      driverName: "$RevenueName",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    RevenueOnly: {
      driverName: `Revenue Only${SpecialChar.DriverNameESCChar}$RevenueName`,
      fieldName: "RevenueOnly",
      driverID: "revenue_only",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.RevenuesStore.length >=
      global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalRevenues;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of ${global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalRevenues} streams in Revenue. 
            Please reorganize your streams in Revenue. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.IsCreated = true;
        this.ID = res.id;
        callBack(this);
      });
    }
  };

  static GetEmptyRevenue = (callBack) => {
    const revenue = Revenue.createNewEmpty();
    revenue.SaveNew((r) => callBack(r));
  };

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        global.Modeliks.DriversStore.createDriversFrom(
          this.ID,
          this.constructor.TableName,
          this.Totals,
          this,
        );
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  static TableName = datastructure.Finance_Revenues.TableName;

  static convert_Revenue = (revenue) => {
    const newRevenue = new Revenue();
    newRevenue.RevenueName = revenue.RevenueName;
    newRevenue.RevenueType = RevenueTypes.RevenueOnly;
    newRevenue.IsCreated = true;
    newRevenue.ID = revenue.ID;
    newRevenue.Totals = CalculatedDriver.createDriverFromTable(
      newRevenue,
      Revenue.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newRevenue.RevenueOnly = CalculatedDriver.createDriverFromTable(
      newRevenue,
      Revenue.DriversDesc.RevenueOnly.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newRevenue.Totals.setFormula(newRevenue.RevenueOnly.ID_f);
    newRevenue.changeDriversName();
    return newRevenue;
  };

  static createNewEmpty = (RevenueName = "") => {
    const revenue = new Revenue();
    revenue.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    revenue.RevenueName = RevenueName;
    revenue.RevenueType = RevenueTypes.UnitSales;
    return revenue;
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.RevenuesStore.removeItemByID(this.ID);
      this.getAllDrivers().forEach((d) => global.Modeliks.DriversStore.removeItemByID(d.ID));
      const accountReceivable = global.Modeliks.WorkingCapitalStore.find(
        (d) => d.Type === WorkingCapitalTypes.AccountReceivable,
      );
      accountReceivable.cleanWorkingCapital(this.ID, Revenue.TableName, () => {
        if (callBack) {
          callBack();
        }
      });
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        if (!this[driverDesc.fieldName].DriverName) {
          this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace(
            "$RevenueName",
            this.RevenueName,
          );
        } else {
          if (driverDesc.driverID === "total") {
            this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace(
              "$RevenueName",
              this.RevenueName,
            );
          }
        }
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  cloneStream = (callBack, shouldCloneDrivers = true) => {
    const newRevenue = Revenue.createNew(this);
    let OldDrivers = this.getAllDrivers();
    let newDrivers = newRevenue.getAllDrivers();

    OldDrivers.forEach((driver, index) => {
      let olddriver = driver;
      let newdriver = newDrivers[index];

      if (olddriver.Formula) {
        newdriver.Formula = olddriver.Formula;
        newdriver.replaceAll(this.Ref_ID, newRevenue.Ref_ID);
      }

      for (let i = 0; i < olddriver.Values.length; i++) {
        if (olddriver) console.log("Old", olddriver.Values[i], newdriver.Values[i]);
      }
    });
  };

  static getRevenueTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(
      Revenue.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Revenue.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Revenue",
        true,
        true,
        false,
      );
    }

    // const totalDrivers = global.Modeliks.DriversStore.filter(d=>d.Ref_Table == "Finance_Revenues" &&  d.Ref_Field == 'total');

    const totalDrivers = global.Modeliks.RevenuesStore.map((d) => d.Totals);

    driver.setFormula_Sum(totalDrivers);

    return driver;
  };

  static getUnitSalesTotals = () => {
    const driverID = "unitsales_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Revenue.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Revenue.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Product Unit Sales",
        true,
        true,
        false,
      );
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.UnitSales,
    ).map((d) => d.UnitSales);
    driver.setFormula_Sum(totalDrivers);
    driver.Formula = null;

    return driver;
  };

  static getBillableHoursTotals = () => {
    const driverID = "billablehours_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Revenue.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Revenue.TableName,
        driverID,
        "totals",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Total Billable Hours",
        true,
        true,
        false,
      );
    }
    const totalDrivers = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.BillableHours,
    ).map((d) => d.BillableHours);
    driver.setFormula_Sum(totalDrivers);
    driver.Formula = null;

    return driver;
  };

  static getRevenueGrowth = (revenueDriver, IsTemporary = true) => {
    const driverID = revenueDriver.Ref_ID;
    // let driver = global.Modeliks.DriversStore.getItem(revenueDriver.ID+'-growth');

    let driver = global.Modeliks.DriversStore.getItem(
      revenueDriver.Ref_Table + "-" + driverID + "-growth",
    );

    if (driver == null) {
      // driver = CalculatedDriver.createDriver(revenueDriver.Ref_Table, driverID, revenueDriver.Ref_Field + "-growth", UnitTypes.Percentage, DriverCategories.Sum, revenueDriver.DriverName + " Growth", true, IsTemporary);

      driver = CalculatedDriver.createDriver(
        revenueDriver.Ref_Table,
        driverID,
        "growth",
        UnitTypes.Percentage,
        DriverCategories.Sum,
        revenueDriver.DriverName + " Growth",
        true,
        revenueDriver.DriverName !== "Chart" && revenueDriver.Ref_Table === Revenue.TableName,
        false,
      );
    }
    const dates = [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.years_all,
    ];

    dates.forEach((date) => {
      const curDateDriver = driver.getItemByDateSufix(date.sufix);
      const prevDate = dates.find(
        (d) => d.Order === date.Order - 1 && d.PeriodType === date.PeriodType,
      );

      if (prevDate) {
        const prevDateValue = revenueDriver.getItemByDateSufix(prevDate.sufix);
        const curDateValue = revenueDriver.getItemByDateSufix(date.sufix);
        curDateDriver.Formula = `MxMath.Round((${curDateValue} - ${prevDateValue}) / ${prevDateValue} * 100)`;
      } else {
        curDateDriver.Unit = UnitTypes.Hide;
        curDateDriver.Formula = null;
        curDateDriver.Value = null;
      }
    });

    return driver;
  };
}

export default Revenue;
